import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { ActivityIndicator, View } from 'react-native';
import { ApolloProvider } from '@apollo/react-hooks';

import color from '../style/color';
import { ApolloClient } from '../api/graphql/client';
import { getRegion, useRegionDetection } from '../lib/auth/region';
import { checkIsSignedIn } from '../lib/common/cognito';
import { getBrowserExtensionStoreUrl } from '../lib/common/browser';
import { useOauthRedirect } from '../lib/auth/oauthRedirect';
import LandingNavigator from './landing/LandingNavigator';
import AuthNavigator from './auth/AuthNavigator';
import SignInSucceededPage from '../pages/auth/SignInSucceededPage';
import OnboardingNavigator from './onboarding/OnboardingNavigator';
import HomeNavigator from './home/HomeNavigator';
import TrackingModal from '../components/common/TrackingModal';
import BrowserExtensionAutoLoginPage from '../pages/auth/BrowserExtensionAutoLoginPage';
import OnlineOfferActivationPage from '../pages/public/OnlineOfferActivationPage';
import IOSDonationPage from '../pages/public/IOSDonationPage';
import IosAutoDonationConfirmationPage from '../pages/public/IosAutoDonationConfirmationPage';
import FloaIframePage from '../pages/public/FloaIframePage';
import MobileSafariExtensionInstallationPage from '../pages/public/MobileSafariExtensionInstallationPage';
import FloaPaymentPageWithUrlParams from '../pages/public/FloaPaymentPageWithUrlParams';
import ProductDiscoveryPage from '../pages/products/ProductDiscoveryPage';
import OnlineBackgroundOfferActivationPage from '../pages/public/OnlineBackgroundOfferActivationPage';

function RootNavigator({ apolloClient }: { apolloClient: ApolloClient | undefined }) {
    return (
        <Router>
            <RootNavigatorRoutes {...{ apolloClient }} />
        </Router>
    );
}

function RootNavigatorRoutes({ apolloClient }: { apolloClient: ApolloClient | undefined }) {
    const isRegionLoading = useRegionDetection(apolloClient);
    useOauthRedirect();
    if (isRegionLoading)
        return (
            <View style={{ height: '100vh', justifyContent: 'center' }}>
                <ActivityIndicator size="large" color={color.alto} />
            </View>
        );
    return (
        <View style={{ flex: 1 }}>
            <Switch>
                <Route
                    path="/landing"
                    render={() => {
                        if (!checkIsSignedIn()) {
                            if (apolloClient)
                                return (
                                    <ApolloProvider client={apolloClient}>
                                        <LandingNavigator />
                                        <TrackingModal />
                                    </ApolloProvider>
                                );
                            return null;
                        } else return <Redirect to={{ pathname: '/home', search: window.location.search }} />;
                    }}
                />
                <Route
                    path="/auth"
                    render={() => {
                        if (!checkIsSignedIn())
                            return (
                                <>
                                    <AuthNavigator />
                                    {apolloClient ? (
                                        <ApolloProvider client={apolloClient}>
                                            <TrackingModal />
                                        </ApolloProvider>
                                    ) : null}
                                </>
                            );
                        else return <Redirect to={{ pathname: '/home', search: window.location.search }} />;
                    }}
                />
                <Route
                    path="/onboarding"
                    render={() => {
                        if (checkIsSignedIn()) {
                            if (apolloClient)
                                return (
                                    <ApolloProvider client={apolloClient}>
                                        <OnboardingNavigator />
                                        <TrackingModal />
                                    </ApolloProvider>
                                );
                            return null;
                        } else return <Redirect to={{ pathname: '/auth', search: window.location.search }} />;
                    }}
                />
                <Route
                    path="/home"
                    render={() => {
                        if (checkIsSignedIn()) {
                            if (apolloClient)
                                return (
                                    <ApolloProvider client={apolloClient}>
                                        <HomeNavigator />
                                        <TrackingModal />
                                    </ApolloProvider>
                                );
                            return null;
                        } else return <Redirect to={{ pathname: '/auth', search: window.location.search }} />;
                    }}
                />
                <Route
                    path="/browser-extension"
                    exact={true}
                    render={() => {
                        const region = getRegion();
                        const browserExtensionStoreUrl = getBrowserExtensionStoreUrl(region);
                        window.location.replace(browserExtensionStoreUrl);
                        return null;
                    }}
                />
                <Route path="/browser-extension/auto-login" render={() => <BrowserExtensionAutoLoginPage />} />
                <Route
                    path="/online-offer-activation/:userId/:offerId/:stackMode?"
                    render={() => {
                        return <OnlineOfferActivationPage />;
                    }}
                />
                <Route
                    path="/online-background-offer-activation/:userId/:offerId/:stackMode?"
                    render={() => {
                        return <OnlineBackgroundOfferActivationPage />;
                    }}
                />
                <Route
                    path="/sign-in-succeeded"
                    render={() => {
                        return <SignInSucceededPage />;
                    }}
                />
                <Route
                    path="/redeem-ios-donation/:userId/:token/:rewardId/:rewardTitle/:rewardValue/:rewardLogoUrl/:stackMode?"
                    render={() => {
                        return <IOSDonationPage />;
                    }}
                />
                <Route
                    path="/ios-auto-donation-confirmation/:userId/:token/:rewardId/:rewardTitle/:rewardLogoUrl/:stackMode?"
                    render={() => {
                        return <IosAutoDonationConfirmationPage />;
                    }}
                />
                <Route
                    path="/floa-iframe"
                    render={() => {
                        return <FloaIframePage />;
                    }}
                />
                <Route
                    path="/floa-payment-page-with-url-params"
                    render={() => {
                        return <FloaPaymentPageWithUrlParams />;
                    }}
                />
                <Route
                    path="/mobile-safari-extension-installation"
                    render={() => {
                        return <MobileSafariExtensionInstallationPage />;
                    }}
                />
                <Route
                    path="/product-discovery/:conversationId?"
                    render={() => {
                        if (checkIsSignedIn()) {
                            if (apolloClient)
                                return (
                                    <ApolloProvider client={apolloClient}>
                                        <ProductDiscoveryPage />
                                    </ApolloProvider>
                                );
                            return null;
                        } else return <Redirect to={{ pathname: '/auth', search: window.location.search }} />;
                    }}
                />
                <Route path="*">
                    <Redirect to={{ pathname: '/landing', search: window.location.search }} />
                </Route>
            </Switch>
        </View>
    );
}

export default RootNavigator;
